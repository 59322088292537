export default function init(){
  const menuItems = document.querySelectorAll('a[href^="#"]');

  function getScrollTopByHref(element) {
    const id = element.getAttribute('href');
    return jQuery(id).offset().top
  }

  function scrollToIdOnClick(event) {
    event.preventDefault();
    const to = getScrollTopByHref(event.currentTarget);
    jQuery('html,body').animate({scrollTop: to},'slow');
  }

  menuItems.forEach(item => {
    item.addEventListener('click', scrollToIdOnClick);
  });
}