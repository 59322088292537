// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

window.jQuery = window.$ = jQuery;

import 'bootstrap'
import 'jquery-mask-plugin';
import 'select2'
import 'select2/dist/js/i18n/pt-BR'
import 'select2/dist/css/select2.css'
import '@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4.css'
import 'jquery.maskMoney.js'

import initSmoothScroll from 'utils/smoothScroll'

require("@rails/ujs").start()
//  require("turbolinks").start()
//  require("@rails/activestorage").start()
//  require("channels")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

jQuery(document).ready(function () {

  initSmoothScroll();

  function sendFile(fileData) {
    return $.ajax({
      url: '/file',
      type: 'POST',
      data: fileData,
      cache: false,
      contentType: false,
      processData: false,
      async: false,
    })
    .then(function (data) {
      var token = data.token;
      return token
    })
    .catch(function (err) {
      console.error(err);
      var message = err.responseJSON?.message;
      if (!message) message = 'Não foi possível salvar a Fatura';
      $('.toast > .toast-body').html(message);
      $('.toast').toast('show');
      return false
    })
  };

  $('form#landingpage').on('submit', async function (ev) {
    var form = ev.target;

    form.classList.add('was-validated');
    ev.preventDefault();
    if (form.checkValidity() === false) {
      ev.stopPropagation();
      return false
    }

    var formData = getFormData();
    var file = $('#input-fatura')[0].files[0];
    if (file) {
      var fileData = new FormData();
      fileData.append('file', file);

      var fileReturn = await sendFile(fileData)
      if (fileReturn) formData.append('light_bill[token]', fileReturn)
      else return;
    }

    formData.append('capture', 1);

    $.ajax({
      url: '/save',
      type: 'POST',
      data: formData,
      cache: false,
      contentType: false,
      processData: false,
    });

    updateRecaptchas()
    try { fbq('track', 'Lead') } catch (e) {};

    $('#modal-submit').modal('show');
    $('#modal-submit #input-whats').val($('#input-phone').val());
  })

  var updateRecaptchas = function () {
    $("[data-recaptcha-site]").each(function (index, el) {
      var $el = $(el);
      grecaptcha.ready(function () {
        grecaptcha.execute($el.data('recaptcha-site'), { action: $el.data('recaptcha-action') }).then(function (token) {
          $el.val(token);
        });
      });
    })
  };

  setTimeout(updateRecaptchas, 1000);

  function getFormData() {
    var form = new FormData();

    form.append('name', $('#input-name').val());
    form.append('email', $('#input-mail').val());
    form.append('phone', $('#input-phone').val());
    form.append('profile', $('#input-perfil').val());
    form.append('cep', $('#input-cep').val());
    form.append('spent_value', Number($('#input-media-consumo').val()) || null);

    if ($('#input-cep').is(':visible')) {
      form.append('city_id', $('#input-city-by-cep').val());
    } else {
      form.append('city_id', $('#input-city').val());
    }

    form.append('recaptcha', $('#recaptcha_token_1').val());

    form.append('lp_id', $('#lp_id').val())
    return form;
  }

  $('form#form-modal').on('submit', function (ev) {
    var form = ev.target;

    form.classList.add('was-validated');
    ev.preventDefault();
    if (form.checkValidity() === false) {
      ev.stopPropagation();
      return false
    }

    $('#btn-submit-modal').hide();
    $('#btn-submit-modal-loading').show();

    var formData = getFormData();

    formData.append('contact_preference', $('input[name="contato"]').filter(':checked').val());
    formData.append('whatsapp', $('#input-whats').val());
    formData.append('interest', $('#input-interesse').val());

    $.ajax({
      url: '/save',
      type: 'POST',
      data: formData,
      success: function (data) {
        $('#modal-submit').modal('hide');
        $('#modal-success').modal('show');
        var runScript = $('#modal-success .script').val();
        if (runScript) $('#modal-success').append(runScript);
      },
      cache: false,
      contentType: false,
      processData: false,
    }).fail(function (resp) {
      if (resp.responseJSON) {
        $('.toast > .toast-body').html(resp.responseJSON.message)
      }

      $('.toast').toast('show')
    }).always(function () {
      $('#btn-submit-modal').show();
      $('#btn-submit-modal-loading').hide();
      updateRecaptchas();
    });
  })

  $('.input-file').each(function () {
    var $input = $(this),
      $label = $input.next('.js-labelFile'),
      labelVal = $label.html();

    $input.on('change', function (element) {
      var fileName = '';
      if (element.target.value) fileName = element.target.value.split('\\').pop();
      fileName ? $label.addClass('has-file').find('.js-fileName').html(fileName) : $label.removeClass('has-file').html(labelVal);
    });
  });

  $('#input-cep').mask('00000-000'); //specifying options
  $('#input-phone').mask('(00) 00000-0000'); //specifying options
  $('#input-whats').mask('(00) 00000-0000'); //specifying options

  $('#modal-submit').on('show.bs.modal', function (e) {
    var name = $('#input-name').val();
    var firstName = ('' + name).split(' ')[0]

    if (firstName.length) {
      $('span.firstName').html(firstName + ',')
    } else {
      $('span.firstName').html('')
    }
  });

  $('.btn-show-city').on('click', function (e) {
    $('#group-input-cep').hide();
    $('#input-cep').prop('disabled', true);

    $('#group-input-city').show();
    $('#input-city').prop('disabled', false);
  })

  $('#input-city').select2({
    id: function (bond) { return bond._id; },
    language: "pt-BR",
    placeholder: "Digite aqui",
    theme: 'bootstrap4',
    minimumInputLength: 3,
    multiple: false,
    delay: 300,
    ajax: {
      url: '/utils/search_cities',
      dataType: 'json',
      data: function (params) {
        const queryParams = params.term.replace(/\s/g, '');
        var query = {
          q: queryParams,
          page: params.page || 1
        }

        // Query parameters will be ?search=[term]&page=[page]
        return query;
      },
      results: function (bond, page) {
        return { results: bond.results, more: false }
      }
    }
  });


  $('input[name="contato"]').on('change', function (e) {
    var $el = $(e.target);
    var val = $el.val();

    if (val === 'whatsapp') {
      $('#col-input-whatsapp').show();
      $('#input-whats').prop('disabled', false).focus();
    } else {
      $('#col-input-whatsapp').hide();
      $('#input-whats').prop('disabled', true);
    }
  })

  $('#input-cep').on('blur', function (e) {
    var $el = $(e.target);
    const val = $el.val();
    const cep = ('' + val).replace(/\D/g, "");

    if (cep === '') {
      $el.val('')
      return
    }

    $el[0].setCustomValidity('Aguarde enquanto validamos a informação');
    $('#invalid-cep').html('Aguarde enquanto validamos a informação').show();

    $.ajax({
      url: '/utils/cep_validator/' + cep,
      dataType: 'json',
    })
      .done(function (data) {
        if (data.status === false) {
          $('#invalid-cep').html(data.message).show()
          $el.val('');
        }
        if (data.city && data.city.id) {
          $('#input-city-by-cep').val(data.city.id)
          $el[0].setCustomValidity('');
          $('#invalid-cep').hide();
        }
      })
      .catch(function(exception) {
        $el[0].setCustomValidity('Não conseguimos validar seu CEP, você pode tentar a opção `não sei o CEP`!');
        $('#invalid-cep').html('Não conseguimos validar seu CEP, você pode tentar a opção `não sei o CEP`!').show();
        try { console.error(exception.responseText); } catch(e) { console.error(e) }
      });
  });

  $('#pg-modal-confirm-btn').on('click', function (e) {
    e.preventDefault()
    window.location.reload(true)
  });

  $("input[type=file]").bind("change", function () {
    var ext = $('#input-fatura').val().split('.').pop().toLowerCase();
    var fileTypes = ['gif', 'png', 'jpg', 'jpeg', 'tiff', 'pdf'];
    var file = $.inArray(ext, fileTypes) == -1;

    if (!file === false || this.files[0].size > 10000000) {
      !file === false ? errorMessage('Arquivo inválido, somente imagem.') : errorMessage('Somente arquivo menor 10 mb');
      $(".js-fileName").css("color", "red");
      $(".js-labelFile").removeClass("has-file");
      $('#btn-submit-form').attr('disabled', true);

    } else {
      $(".js-labelFile").addClass("has-file");
      $(".js-fileName").css("color", "#5832f0");
      $('#btn-submit-form').attr('disabled', false);
    }
  });

  function errorMessage(text) {
    $('.toast > .toast-body').html(text);
    $('.toast').toast('show')
  }

}, false);
